export default async function getCodePass(email) {

    try {
        const response = await fetch(`https://dashboard.atlantainvest.com.br/api/public/reset-password.php`,{
            method:'post',
            body: JSON.stringify ({
                email,
            
         
           
            })
        });
  
      const res = await response.json();
      console.log(res)
    
        return res;
        
        
        
    } catch (error) {
        return {
            error:true,
            mensage:error
        };
        
        
    };
       
    
  };