import './customButton.css';


export default function CustomButton({ text, click }) {
    return (
        <>
            <button 
                className="CustomButton__bnt"
                onClick={click}
            >
                {text}
            </button>
        </>

    );

}